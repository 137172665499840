@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500&display=swap');

*{
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}
:root {
  /* --color-primary: #27cafc; */
  /* --color-primary: #2b6777; */
  --color-primary: #0083b0;
  --light: #ffffff;
  --light-two: #f5f5f5;
  --dark: #000;
  --dark-web-color: #01294d;
}

a {
  text-decoration: none;
}

body {
  box-sizing: border-box;
}